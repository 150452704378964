<template>
    <div>
        <div id="div1">
        </div>
    </div>
</template>
<script>
    import E from "wangeditor";
    import {uploadingForRichText} from "@/api/uploadApi";

    export default {
        name: "Editor",
        data() {
            return {
                editor: null,
                editorContent: '',

            };
        },
        mounted() {
            this.editor = new E('#div1')
            this.editor.config.height = 585
            this.editor.config.uploadImgServer = uploadingForRichText
            this.editor.config.uploadFileName = 'file'
            // this.editor.config.uploadImgParams = {
            //   type: 'richText',
            // }
            this.editor.config.zIndex = 1
            this.editor.config.uploadImgMaxLength = 1
            this.editor.create()
        },
        methods: {
            get() {
                return this.editor.txt.html();
            },
            clear() {
                this.editor.txt.clear()
            }
        }

    }
</script>
